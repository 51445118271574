/* ==========================================================================
Bootstrap4 - Navbar
========================================================================== */
const body = $('body');
const navbar = $('#navbar');
const navbarHeight = navbar.outerHeight();

$(window).scroll(function () {
  const scroll = $(window).scrollTop();

  if (scroll >= navbarHeight) {
    body.addClass('navbar--scroll');
  } else {
    body.removeClass('navbar--scroll');
  }
});

// console.log('navbar.js is loading');
