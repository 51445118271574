/* ==========================================================================
Popup
========================================================================== */
import Cookies from 'js-cookie';

$(document).ready(() => {
  if (!Cookies.get('popup')) {
    setTimeout(function () {
      $('#popupModal').modal({
        backdrop: 'static', keyboard: false
      });
    }, 500);
  }

  $('.btn-allowed').on('click', function () {
    // bootstrap modal callback function
    // set cookie
    Cookies.set('popup', 'valid', { expires: 1, path: '/' });
  });
});

// console.log('popup.js is loading');
