/* ==========================================================================
jQuery
https://jquery.com/
========================================================================== */
import $ from 'jquery'; // eslint-disable-line

/* ==========================================================================
Bootstrap JS Files
https://getbootstrap.com/
========================================================================== */
import 'bootstrap';

/* ==========================================================================
Bootstrap
========================================================================== */
import './bootstrap4/navbar-collapse.js';
import './bootstrap4/navbar.js';

/* ==========================================================================
Animate CSS
========================================================================== */
import './animate/animate.js';

/* ==========================================================================
Hero
========================================================================== */
import './hero/hero-swiper.js';
import './hero/hero-sound.js';

/* ==========================================================================
LazyLoad
https://github.com/verlok/lazyload
========================================================================== */
import './lazy-load/lazy-load.js';

/* ==========================================================================
Page List Plus
========================================================================== */
import './page-list-plus/page-list-plus.js';

/* ==========================================================================
Popup
========================================================================== */
import './popup/popup.js';

/* ==========================================================================
Smoothscroll
========================================================================== */
import './smoothscroll/smoothscroll.js';
