/* ==========================================================================
Animate CSS
========================================================================== */
/* eslint-disable */
const options = {
  root: null, // use the document's viewport as the container
  rootMargin: '0px', // % or px - offsets added to each side of the intersection
  threshold: 0.5 // percentage of the target element which is visible
};

const callback = (entries) => {
  entries.forEach (entry => {
    // If entry (box) is visible - according with the params set in `options`
    // then adds `isVisible` class to box
    // otherwise removes `isVisible` class
    const animation = entry.target.dataset.animate;

    if (entry.isIntersecting && !entry.target.classList.contains('observed')) {
      entry.target.classList.add(animation);
      entry.target.classList.add('observed');
    } else {
      entry.target.classList.remove(animation);
    }
  });
};

// Create the intersection observer instance by calling its constructor and passing it a
// callback function to be run whenever a threshold is crossed in one direction or the other:
const observer = new IntersectionObserver(callback, options);

// Get all the `.box` from DOM and attach the observer to these
document.querySelectorAll('.animate__animated').forEach(el => {
  observer.observe(el);
});
/* eslint-enable */
