/* ==========================================================================
Hero
========================================================================== */
/* eslint-disable */
var soundToggle = document.getElementById('sound__toggle')

soundToggle.addEventListener('click', function(event) {
  event.preventDefault();
  var video = document.getElementById('video')
  if (video.muted == true) {
    video.muted = false;
    soundToggle.innerHTML = "<i class=\"fa fa-volume-up\" aria-hidden=\"true\"></i> Sound on";
  } else {
    video.muted = true;
    soundToggle.innerHTML = "<i class=\"fa fa-volume-off\" aria-hidden=\"true\"></i> Sound off";
  }
});
