/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
// Core version + modules:
import Swiper, { Autoplay, EffectFade } from 'swiper';

// Configure Swiper to use modules
Swiper.use([Autoplay, EffectFade]);

/* ==========================================================================
Hero
========================================================================== */
function heroImages () {
  const swiper = new Swiper('#heroImages', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    effect: 'fade'
  });
  return swiper;
}

if ($('#heroImages').length) {
  heroImages();
}

// console.log('hero-swiper.js is loading');
