/* ==========================================================================
LazyLoad
https://github.com/verlok/lazyload
========================================================================== */
const LazyLoad = require('vanilla-lazyload');

const lazyLoadInstance = new LazyLoad({
  elements_selector: '.lazy'
});

if (lazyLoadInstance) {
  lazyLoadInstance.update();
}
